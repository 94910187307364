body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  color: #302b2b;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}

::-webkit-scrollbar-thumb {
  background: #302b2b;
  border-radius: 40px;
}

@font-face {
  src: url('./fonts/ArialRounded.ttf');
  font-family: 'Arial Rounded';
}

@font-face {
  src: url('./fonts/NeueMontreal-Regular.ttf');
  font-family: 'Neue Montreal';
}

@font-face {
  src: url('./fonts/PPMondwest-Regular.ttf');
  font-family: 'PP Mondwest';
  font-weight: 300;
}

@font-face {
  src: url('./fonts/PPMondwest-Bold.ttf');
  font-family: 'PP Mondwest';
  font-weight: 600;
}

button {
  background: center / 160% no-repeat url('./images/drop.png');
  outline: none;
  transition: transform 0.5s;
}

button:hover,
.selected_button {
  transform: scale(1.25);
  transition: all 0.5s;
  opacity: 1;
}

@media and (max-width: 700px) {
  .selected_button {
    transform: scale(2);
    transition: all 0.5s;
    opacity: 1;
  }
}
